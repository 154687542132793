import React from "react";
import Navbar from "../components/Navbar";
import styles from "../css/About.module.css";
import Footer from "../components/Footer";


function About() {
  return (
    <div className={styles.aboutmaincontainer}>
      <div className={styles.navbarcontainer}>
        <Navbar></Navbar>
      
        <div className={styles.container1right}></div>
      </div>
       <h1 className="text-4xl text-center mt-10 mb-5 font-bold uppercase ">Meet the founders</h1>
      <div className={styles.container2}>
        <div className=  {styles.container2imagecontainer}>
          <img
            src={require("../assets/shambhavi.jpg")}
            className="h-[170px] rounded-full  "
            alt="Nyahari"
          ></img>
          <div className={styles.container2textleftcontainer}>
            <h1>Shambhavi Kulkarni</h1>
            <h2>Founder</h2>
          </div>
        </div>

        <div className={styles.container2textcontainer}>
          <h1>Not a dream, but a mission!</h1>

          <p>
            Nyahari is not merely a dream but a mission! A mission to make
            generations healthier. Working on inculcating healthy food habits
            amongst the generation is a challenge that we as Nyahari have
            accepted. It’s exciting to work with the team. I’m looking forward
            to the impact that we create.
          </p>
        </div>
      </div>

      {/* container3 */}

      <div className={styles.container3}>
        <div className={styles.container3imagecontainer}>
          <img
            src={require("../assets/rutuja2.jpg")}
            className={styles.container3img}
            alt="Nyahari"
          ></img>
          <div className={styles.container3textleftcontainer}>
            <h1>Rutuja Bhagwat (Jain)</h1>
            <h2>Co-founder</h2>
          </div>
        </div>

        <div className={styles.container3textcontainer}>
          <h1>You are what you eat</h1>

          <p className="text-left">
            “You are what you eat, so don’t be fast, cheap, easy, or fake.”
            Nyahari aims to change eating habits for generations to come, join
            this mission and experience the joy of a healthy lifestyle with
            Nyahari and team.
          </p>
        </div>
      </div>

     

      <Footer></Footer>
    </div>
  );
}

export default About;