import React from "react";
import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
import styles from "./Salads.module.css";
import Backbar from "../components/Backbar";

function Salads() {
  return (
    <div className={styles.salads}>
      <div className={styles.saladsnav}>
        <Backbar></Backbar>
      </div>
      <div className={styles.saladsh1}>
        <h1 className={styles.saladsmainh1}>salads</h1>
      </div>

      <div className={styles.saladscards}>
        <div className={styles.salads1}>
          <h1 className={styles.salads1h1}>Salad</h1>

          <div className={styles.salads1txt}>
            <h1>Beetastic Tomato Salad</h1>
            
          </div>

          <div className={styles.salads1txt}>
            <h1>The Royal Paneer Affair</h1>
           
          </div>

          <div className={styles.salads1txt}>
            <h1>Chickpea Crunch Salad</h1>
           
          </div>

          <div className={styles.salads1txt}>
            <h1> Sweet ToothFruit Custard</h1>
            
          </div>

      

          <div className={styles.salads1txt}>
            <h1>Barley Belly Bluster</h1>
            
          </div>

          <div className={styles.salads1txt}>
            <h1>Flirty Fruity  salad</h1>
            
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Salads;
