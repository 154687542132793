import React, { useState } from "react";
import Navbar from "../components/Navbar";
import styles from "../css/Feedback.module.css";
function Feedback() {
  const [feedbackdata, setFeedbackData] = useState({
    email: "",
    feedbackmessage: "",
  });

  console.log(feedbackdata.email);
  console.log(feedbackdata.feedbackmessage);

  const changeHandler = (e) => {
    setFeedbackData({ ...feedbackdata, [e.target.name]: e.target.value });
  };

  const submitFeedback = () => {
    const fetchMYAPI = async () => {
      fetch("https://backend.nyahari.com/api/web/feedbackweb", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: feedbackdata.email,
          feedbackmessage: feedbackdata.feedbackmessage,
        }),
      })
        .then((res) => res.json())
        .then(async (data) => {
          console.log(data);
          setFeedbackData({
            email: "",
            feedbackmessage: "",
          });
          alert("Feedback Submitted");
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    };
    {
      feedbackdata.email.length === 0 ||
      feedbackdata.feedbackmessage.length === 0
        ? alert("Please Enter Valid Inputs")
        : fetchMYAPI();
    }
  };
  return (
    <div>
      <Navbar></Navbar>
      <div className={styles.feedback_container}>
        <div className={styles.containerheading}>
          <h1>Give your valuable feedback</h1>
        </div>
        <div className={styles.inputcontainer1}>
          <input
            placeholder="Enter your email"
            type="email"
            name="email"
            onChange={changeHandler}
            required
            value={feedbackdata.email}
          ></input>
        </div>

        <div className={styles.inputcontainer3}>
          <textarea
            placeholder="Share your valuable feedback"
            name="feedbackmessage"
            onChange={changeHandler}
            type="text"
            // rows="5"
            // cols="60"
            // maxLength="150"
            required
            value={feedbackdata.feedbackmessage}
          ></textarea>
        </div>
        <div className={styles.button} onClick={submitFeedback} typeof="submit">
          <button>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
