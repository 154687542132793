import React from "react";
import Navbar from "../components/Navbar";
import styles from "../css/MenuNew.module.css";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Sandwhiches from "../assets/Paneersandwhich.png"
import Chaat from "../assets/Makhnachaat.png"
import Season from "../assets/Juicenew.png"

function MenuNew() {
  return (
    <div className={styles.container_main}>
      <div className={styles.nav_container}>
        <Navbar></Navbar>
      </div>

      <div className={styles.ourmenu_container}>
        <h1>Our Menu</h1>
      </div>
      <div className={styles.box_container_main}>
        <div className={styles.box_container}>
          <Link to="/menu/quick-bites" className={styles.box1}>
            <div>
              <div className={styles.box_information}>
                <div className={styles.image_container}>
                  <img
                    src={Sandwhiches}
                    className={styles.image}
                    alt="Nyahari"
                  ></img>
                </div>
                <h1>Sandwhiches</h1>
              </div>
            </div>
          </Link>

          <Link to="/menu/Taste-of-Maharashtra" className={styles.box2}>
            <div>
              <div className={styles.box_information}>
                <div className={styles.image_container}>
                  <img
                    src={Chaat}
                    className={styles.image}
                    alt="Nyahari"
                  ></img>
                </div>
                <h1>Chaat</h1>
              </div>
            </div>
          </Link>

          <Link to="/menu/Taste-of-India" className={styles.box3}>
            <div>
              <div className={styles.box_information}>
                <div className={styles.image_container}>
                  <img
                    src={Season}
                    className={styles.image}
                    alt="Nyahari"
                  ></img>
                </div>
                <h1>Season Special</h1>
              </div>
            </div>
          </Link>

          <Link to="/menu/Drinks-for-a-Kick-Start" className={styles.box4}>
            <div>
              <div className={styles.box_information}>
                <div className={styles.image_container}>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRg4CHwfg0lTs290iWCUOx3wiCD1u_9uaQA0HjSElRK6ioJ-LvxP6ay86VbbS53Xt-CqCY&usqp=CAU"
                    className={styles.image}
                    alt="Drinks"
                  ></img>
                </div>
                <h1>Juices</h1>
              </div>
            </div>
          </Link>

          <Link to="/menu/salads" className={styles.box5}>
            <div>
              <div className={styles.box_information}>
                <div className={styles.image_container}>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7QoTf_wfrmJur7EWWEFOBxkDznUmBjoF8-g&usqp=CAU"
                    className={styles.image}
                    alt="Riya"
                  ></img>
                </div>
                <h1>Salads</h1>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className={styles.footer}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default MenuNew;
