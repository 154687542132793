import React from "react";
import Backbar from "../components/Backbar";
import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
import styles from "./Drinks.module.css";
function Drinks() {
  return (
    <div className={styles.drink}>
      <div className={styles.drinknav}>
        {/* <Navbar></Navbar> */}
        <Backbar></Backbar>
      </div>
      <div className={styles.drinkh1}>
        <h1 className={styles.drinkmainh1}>Drinks For Kick Start</h1>
      </div>

      <div className={styles.drinkcards}>
        <div className={styles.drink1}>
          <h1 className={styles.drink1h1}>Juices</h1>
          <div className={styles.drink1txt}>
            <h1>Tropical Pineapple Juice</h1>
            {/* <h2>RS 50</h2> */}
          </div>
          <div className={styles.drink1txt}>
            <h1>Summner Sipper Watermelon Juice</h1>
            {/* <h2>RS 60</h2> */}
          </div>

          <div className={styles.drink1txt}>
            <h1>Tangy Orange Juice</h1>
            {/* <h2>RS 60</h2> */}
          </div>
          <div className={styles.drink1txt}>
            <h1>Pomo Paradise Juice</h1>
            {/* <h2>RS 60</h2> */}
          </div>
        </div>

        {/* <div className={styles.drink2}>
          <h1 className={styles.drink2h1}>Smoothie</h1>
          <div className={styles.drink2txt}>
            
            
          </div>

          <div className={styles.drink2txt}>
           
          </div>

          <div className={styles.drink2txt}>
            
          </div>
        </div> */}

        {/* <div className={styles.drink3}>
          <h1 className={styles.drink3h1}></h1>
          <div className={styles.drink3txt}>
           
          </div> 

           <div className={styles.drink3txt}>
            
          </div>

          <div className={styles.drink3txt}>
           
          </div>

          <div className={styles.drink3txt}>
         
          </div>

          <div className={styles.drink3txt}>
           
          </div>

          <div className={styles.drink3txt}>
           
          </div>

          <div className={styles.drink3txt}>
           
          </div>

          <div className={styles.drink3txt}>
          
          </div>
        </div> */}
      </div>

      <div className={styles.footer}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Drinks;
