import "./App.css";
import Home from "./pages/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Feedback from "./pages/Feedback";
import Faq from "./pages/Faq";
import Menu from "./pages/Menu";
import Features from "./pages/Features";
import Rate from "./pages/Rate";
import MenuNew from "./pages/MenuNew";
import Quick from "./MenuCategory/Quick";
import TasteOfMaharashtra from "./MenuCategory/TasteOfMaharashtra";
import TastOfIndia from "./MenuCategory/TasteOfIndia";
import Drinks from "./MenuCategory/Drinks";
import Salads from "./MenuCategory/Salads";
import Careers from "./pages/Careers";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/features" element={<Features />}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route exact path="/rate" element={<Rate />}></Route>
          <Route exact path="/Team" element={<Careers/>}></Route>

          <Route exact path="/menu" element={<MenuNew />}></Route>
          <Route exact path="/menu/quick-bites" element={<Quick />}></Route>
          <Route
            exact
            path="/menu/Taste-of-Maharashtra"
            element={<TasteOfMaharashtra />}
          ></Route>
          <Route
            exact
            path="/menu/Taste-of-India"
            element={<TastOfIndia />}
          ></Route>
          <Route
            exact
            path="/menu/Drinks-for-a-Kick-Start"
            element={<Drinks />}
          ></Route>
          <Route exact path="/menu/Salads" element={<Salads />}></Route>
          <Route exact path="/faq" element={<Faq />}></Route>
          <Route exact path="/feedback" element={<Feedback />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
