import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Backbar.module.css";
import { ArrowBackIos } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

function Backbar() {
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  return (
    <div className={styles.back_container}>
      <IconButton
        className={styles.icon_button}
        style={{
          marginLeft: 20,
        }}
        onClick={handleClick}
      >
        <ArrowBackIos className={styles.back_icon}></ArrowBackIos>
      </IconButton>
    </div>
  );
}

export default Backbar;
