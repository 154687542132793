import React from "react";
import Navbar from "../components/Navbar";
import styles from "../css/About.module.css";

import { Link } from "react-router-dom";
import Features from "../pages/Features"


function About() {
  return (
    <div className={styles.aboutmaincontainer}>
      <div className={styles.navbarcontainer}>
        <Navbar></Navbar>
      </div>
      <div className={styles.container1}>
        <div className={styles.fade_banner}></div>
        <div className={styles.container1left}>
          <h1 >Freshness in every bite!</h1>

          <p className="text-black">
           Breakfast like a Queen, Lunch like a prince & dinner like a pauper. Let’s make ourselves
            healthier in a world full of diseases by inculcating proper food
            habits. Nyahari takes care of your most important meal i.e. your
            breakfast. Experience the privilege of customizing your breakfast
            and enjoy wholesome nutrition.
          </p>

          <Link to="/menu">
            <button>Explore Menu</button>
          </Link>
        </div>
        <div className={styles.container1right}></div>
      </div>
      \

      <div>
        <Features/>
      </div>


      {/* container3 */}

 

      {/* container3 */}

      {/* Team */}

      {/* <div>
        <div>
          <h1>Team</h1>
        </div>
      </div> */}
      {/* <div className={styles.teams}>
        <h1>Our Team</h1>
      </div>
      <div className={styles.box_container_main}>
        <div className={styles.box_container}>
          <div className={styles.box1}>
            <div className={styles.box_information}>
              <div className={styles.image_container}>
                <img
                  src={require("../assets/gaurav.jpg")}
                  className={styles.image}
                  alt="Nyahari"
                ></img>
              </div>
              <h1>Gaurav Burande</h1>
              <h2>Full Stack Developer</h2>

              <div class="logo_container"></div>
            </div>
          </div>
          <div className={styles.box2}>
            <div className={styles.box_information}>
              <div className={styles.image_container}>
                <img
                  src={require("../assets/sumairan.jpg")}
                  className={styles.image}
                  alt="Nyahari"
                ></img>
              </div>
              <h1>Sumiran Dode</h1>
              <h2>Flutter developer</h2>
            </div>
          </div>
          <div className={styles.box3}>
            <div className={styles.box_information}>
              <div className={styles.image_container}>
                <img
                  src={require("../assets/pradnya.jpg")}
                  className={styles.image}
                  alt="Nyahari"
                ></img>
              </div>
              <h1>Pradnya Deshmukh</h1>
              <h2>Flutter developer</h2>

              <div class="logo_container">
                {/* <img src="../Vector (2).png" alt="">
                   <img src="../linkedin 11.png" alt=""> */}
               {/* </div>
            </div>
          </div>
        </div>
      </div>   */}

      {/* Team */}
      

    </div>
  );
}

export default About;
