import React from "react";
import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
import styles from "./TasteOfIndia.module.css";
import Backbar from "../components/Backbar";
function TasteOfIndia() {
  return (
    <div className={styles.tasteofindia}>
      <div className={styles.tasteofindianav}>
        <Backbar></Backbar>
      </div>
      <div className={styles.tasteofindiah1}>
        <h1 className={styles.tasteofindiamainh1}>Smoothie</h1>
      </div>

      <div className={styles.tasteofindiacards_container}>
        <div className={styles.tasteofindiacards}>
          <div className={styles.tasteofindia1}>
            <div className={styles.tasteofindia1txt}>
              <h1>Mango Madness Smoothie</h1>
             
            </div>

            <div className={styles.tasteofindia1txt}>
              
             
            </div>

            <div className={styles.tasteofindia1txt}>
             
              
            </div>

            <div className={styles.tasteofindia1txt}>
             
             
            </div>

            <div className={styles.tasteofindia1txt}>
             
              
            </div>

            <div className={styles.tasteofindia1txt}>
              
            </div>

            <div className={styles.tasteofindia1txt}>
              
            </div>
          </div>
        </div>

        {/* <div className={styles.tasteofindiacards}>
          <div className={styles.tasteofindia1}>
            <div className={styles.tasteofindia1txt}>
            
            </div>

            <div className={styles.tasteofindia1txt}>
              
            </div>

            <div className={styles.tasteofindia1txt}>
              
            </div>

            <div className={styles.tasteofindia1txt}>
            
            </div>

            <div className={styles.tasteofindia1txt}>
            
            </div>

            <div className={styles.tasteofindia1txt}>
            
            </div>

            <div className={styles.tasteofindia1txt}>
             
            </div>
          </div> */}
        {/* </div> */}
      </div>

      <div className={styles.footer}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default TasteOfIndia;
