import React from "react";
import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
import styles from "./Quick.module.css";
import Backbar from "../components/Backbar";
function Quick() {
  return (
    <div className={styles.quick}>
      <div className={styles.quicknav}>
        <Backbar></Backbar>
      </div>
      <div className={styles.quickh1}>
        <h1 className={styles.quickmainh1}>Sandwhiches</h1>
      </div>

      <div className={styles.quickcards}>
        <div className={styles.quick1}>
          <h1 className={styles.quick1h1}>Sandwiches</h1>

          <div className={styles.quick1txt}>
            <h1>Paneer Powerhouse Sandwhich</h1>
            
          </div>

          <div className={styles.quick1txt}>
            <h1>Fresh Harvest Sandwhich</h1>
           
          </div>

          <div className={styles.quick1txt}>
            <h1>Banana Nutty Sandwhich</h1>
            {/* <h2>RS 65</h2> */}
          </div>

          <div className={styles.quick1txt}>
            <h1>Almond-day Sandwhich</h1>
            {/* <h2>RS 65</h2> */}
          </div>
          <div className={styles.quick1txt} >
          <h1>Popeye's corny Spinach Sandwhich</h1>
            {/* <h2>RS 65</h2> */}
          </div>

        
        </div>

         {/* <div className={styles.quick2}>
          <h1 className={styles.quick2h1}></h1>

          <div className={styles.quick2txt}>
            <h1>Popeye's corny Spinach Sandwhich</h1>
            <h2>RS 50</h2>
          </div>

          <div className={styles.quick2txt}>
            <h1>Sprouts chaat</h1> 
            <h2>RS 60</h2> 
          </div>

          <div className={styles.quick2txt}>
            <h1>Chana Chaat</h1>
            <h2>RS 60</h2>
          </div>

          <div className={styles.quick2txt}>
            <h1>Paneer chaat</h1>
            <h2>RS 90</h2>
          </div>
        </div>  */}

        {/* <div className={styles.quick3}>
          <h1 className={styles.quick3h1}>Rolls</h1>

          <div className={styles.quick3txt}>
            <h1>Spicy veggie Roll</h1>
            <h2>RS 50</h2>
          </div>

          <div className={styles.quick3txt}>
            <h1>Egg Roll</h1>
            <h2>RS 50</h2>
          </div>

          <div className={styles.quick3txt}>
            <h1>Paneer Roll</h1>
            <h2>RS 90</h2>
          </div>

          <div className={styles.quick3txt}>
            <h1>Soya Roll</h1>
            <h2>RS 90</h2>
          </div>
        </div> */}

        {/* <div className={styles.quick3}>
          <h1 className={styles.quick3h1}>Eggie Peggie</h1>
          <div className={styles.quick3txt}>
            <h1>Boiled eggs(2)</h1>
            <h2>RS 20</h2>
          </div>

          <div className={styles.quick3txt}>
            <h1>Half Fry</h1>
            <h2>RS 25</h2>
          </div>

          <div className={styles.quick3txt}>
            <h1>Single omelette</h1>
            <h2>RS 30</h2>
          </div>
          <div className={styles.quick3txt}>
            <h1>Double omelette</h1>
            <h2>RS 50</h2>
          </div>

          <div className={styles.quick3txt}>
            <h1>Scrambled eggs</h1>
            <h2>RS 60</h2>
          </div>
        </div> */}
      </div>

      <div className={styles.footer}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Quick;
