import React from "react";
import Faqbar from "../components/Faqbar";
import "../css/Faq.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Faq() {
  return (
    <div className="pricing_screen_container_main">
      <div>
        <Navbar></Navbar>
      </div>
      <div className="pricing_screen_container">
        <div className="pricing_screen_container_bars">
          <div className="pricing_screen_container_head">
            <h1>Commonly Asked Questions</h1>
          </div>
          <Faqbar
            title="How to become our beta customer?"
            description="It’s simple, you may contact us through our website or any of our social media handles and tell us your interest!"
          ></Faqbar>

          <Faqbar
            title="How do you ensure food quality despite having so many cooks?"
            description="We have certain protocols about the ingredients that our cooks use, to make your breakfast nutritious."
          ></Faqbar>
          <Faqbar
            title="How to join as a cyclist?"
            description="It’s simple, you may contact us through our website or any of our social media handles and tell us your interest"
          ></Faqbar>

          <Faqbar
            title="What does the customisation of breakfast exactly mean?"
            description="It means that you can add more than one item for your breakfast and customise its ingredients according to your wish"
          ></Faqbar>

          <Faqbar
            title="How does advance scheduling work like?"
            description="You can schedule the time along with your menu and your items will be delivered to your doorstep!"
          ></Faqbar>
        </div>
      </div>

      <div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Faq;
