import React, { useState } from "react";
import "../css/Faqbar.css";
// import { Add, Remove } from "@material-ui/icons";
// import { IconButton } from "@material-ui/core";
// import {
//   Facebook,
//   Instagram,
//   Add,
//   Remove,
//   IconButton,
// } from "@mui/icons-material";
import { Add, Remove } from "@material-ui/icons";
function Faqbar({ title, description }) {
  const [show, setShow] = useState(false);
  return (
    <div className="pricebar_container">
      <div className="pricebar_container1">
        <h1>{title}</h1>
        <div className="add_icon_container" onClick={() => setShow(!show)}>
          {show ? <Remove></Remove> : <Add className="add_icon"></Add>}
        </div>
      </div>
      {show ? (
        <div className="pricebar_container2">
          <h2>{description}</h2>
        </div>
      ) : null}
    </div>
  );
}

export default Faqbar;
