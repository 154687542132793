import React from "react";
import Navbar from "../components/Navbar";
import styles from "../css/Features.module.css";
import Footer from "../components/Footer";
function Features() {
  return (
    <div className={styles.featurecontainer}>
      <div className={styles.featurecontainer1}>
        <div className={styles.featurecontainer1txt}>
          <h1 style={{ color: "red" }}>Fresh</h1>
          <h1 style={{ color: "green", whiteSpace: "nowrap" }}>Healthy &</h1>
          <h1>Nutritious</h1>
        </div>
        <img src={require("../assets/chef1.png")}></img>
      </div>

      <div className={styles.featurecontainer2}>
        <h1 className={styles.featuresh1}>Features</h1>
        <div className={styles.featurecontainer2cards}>
          <div className={styles.card1container1}>
            <img
              src={require("../assets/healthy.png")}
              className={styles.img1}
              alt="Nyahari"
            ></img>
            <h1>Healthy Nutrition</h1>
          </div>

          <div className={styles.card1container1}>
            <img
              src={require("../assets/custom_menu.png")}
              className={styles.img2}
              alt="Nyahari"
            ></img>
            <h1>Customisable Menu</h1>
          </div>

          <div className={styles.card1container1}>
            <img
              src={require("../assets/schedule.png")}
              className={styles.img3}
              alt="Nyahari"
            ></img>
            <h1>Advanced Scheduling</h1>
          </div>
        </div>
      </div>

      <div className={styles.featurecontainer2}>
        <div className={styles.featurecontainer2cards}>
          <div className={styles.card1container1}>
            <img
              src={require("../assets/tree.png")}
              className={styles.img4}
              alt="Nyahari"
            ></img>
            <h1>Weekly Retention</h1>
          </div>

          <div className={styles.card1container1}>
            <img
              src={require("../assets/eco.png")}
              className={styles.img5}
              alt="Nyahari"
            ></img>
            <h1>Eco-friendly Packaging</h1>
          </div>

          <div className={styles.card1container1}>
            <img
              src={require("../assets/deliveryman2.png")}
              className={styles.img6}
              alt="Nyahari"
            ></img>
            <h1>Safe Hands</h1>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Features;
