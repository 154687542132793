import React from "react";
import styles from "../css/Navbar.module.css";
import "../css/Navbar.module.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  NavLink,
} from "react-router-dom";
function Navbar() {
  return (
    <div className={styles.navbarcontainer}>
      <div className={styles.navbarcontainerleft}>
        <img
          src={require("../assets/nyahari.png")}
          className={styles.navbarlogoimg}
          alt="Nyahari"
        ></img>
      </div>
      <div className={styles.navbarcontainerright}>
        <ul>
          <NavLink
            to="/"
            className={styles.link}
            activeClassName={styles.active}
          >
            Home
          </NavLink>
          {/* <Link to="/articles" className={styles.link}>
            Articles
          </Link> */}
          <NavLink
            to="/about"
            className={styles.link}
            activeClassName={styles.active}
          >
            About
          </NavLink>
          <NavLink
            to="/Team"
            className={styles.link}
            activeClassName={styles.active}
          >
            Team
          </NavLink>
        
          <NavLink
            to="/contact"
            className={styles.link}
            activeClassName={styles.active}
          >
            Contact Us
          </NavLink>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
