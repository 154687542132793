import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { Container, Radio, Rating } from "../css/RatingStyles";
import styles from "../css/Rate.module.css";
const Rate = () => {
  const [rate, setRate] = useState(0);
  return (
    <div className={styles.ratepage}>
      <div className={styles.ratecontainer}>
        <h1>Rate Us</h1>
        <Container>
          {[...Array(5)].map((item, index) => {
            const givenRating = index + 1;
            return (
              <div>
                <label>
                  <Radio
                    type="radio"
                    value={givenRating}
                    onClick={() => {
                      setRate(givenRating);
                    }}
                  />
                  <Rating>
                    <FaStar
                      color={
                        givenRating < rate || givenRating === rate
                          ? "#FFFF00"
                          : "rgb(192,192,192)"
                      }
                    />
                  </Rating>
                </label>
              </div>
            );
          })}
        </Container>
        <button
          onClick={() => {
            alert(`Are you sure to submit rating`);
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Rate;
