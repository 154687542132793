import React from "react";
import styles from "../css/Footer.module.css";
// import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { Facebook, Instagram, LinkedIn } from "@material-ui/icons";
import { AiOutlineWhatsApp } from "react-icons/ai";

function Footer() {
    return (
        <div>
            <div className={styles.footer}>
                {/* <div className={styles.footer_logo_container}>
          <h1>Nyahari</h1>
        </div> */}
                <div className={styles.footer_container2}>
                    <div className={styles.footer_container2_main}>
                        <h1>Company</h1>
                        <div className={styles.lists}>
                            <Link to="/about" className={styles.link}>
                                <li>About</li>
                            </Link>

                            <Link to="/contact" className={styles.link}>
                                <li>Contact Us</li>
                            </Link>
                            <Link to="/careers" className={styles.link}>
                                <li>Careers</li>
                            </Link>

                            <Link to="/faq" className={styles.link}>
                                <li>FAQ</li>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.footer_container2_main}>
                        <h1>Legal</h1>
                        <div className={styles.lists}>
                            <li>Terms And Conditions</li>
                            <li>Privacy</li>
                        </div>
                    </div>
                    <div className={styles.footer_container2_main}>
                        <h1>Help Center</h1>
                        <div className={styles.lists}>
                            <Link to="/feedback" className={styles.link}>
                                <li>Feedback</li>
                            </Link>

                            <Link to="/rate" className={styles.link}>
                                <li>Rate Us</li>
                            </Link>

                            <Link to="/contact" className={styles.link}>
                                <li>Help</li>
                            </Link>

                            {/* <li>Email Us</li> */}
                        </div>
                    </div>
                    <div className={styles.footer_container2_main}>
                        <h1>Address</h1>
                        <div className={styles.lists}>
                            <li>Pune, Maharashtra – INDIA</li>
                            <li>Email: info@nyahari.com</li>
                            <br></br>
                            <li>Phone:+91 8080275029</li>
                        </div>
                    </div>
                </div>

                <div className={styles.lastsection}>
                    <div className={styles.connect_with_us}>
                        <h1>Connect With Us</h1>
                    </div>
                    <div className={styles.icons}>
                        <a
                            href="https://www.facebook.com/nyahari.pune/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Facebook
                                fontSize="155pc"
                                style={{
                                    width: "35px",
                                    height: "35px",
                                    marginRight: 10,
                                    color: "#FFFF",
                                }}
                            ></Facebook>
                        </a>

                        <a
                            href="https://www.instagram.com/nyahari_pune/?utm_medium=copy_link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Instagram
                                style={{
                                    width: "35px",
                                    height: "35px",
                                    marginRight: 10,
                                    color: "#FFFF",
                                }}
                            ></Instagram>
                        </a>

                        <a
                            href="https://www.linkedin.com/company/nyahari/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LinkedIn
                                style={{
                                    width: "35px",
                                    height: "35px",
                                    color: "#FFFF",
                                    marginRight: 10,
                                }}
                            ></LinkedIn>
                        </a>

                        <a
                            href="https://wa.me/918080275029"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <AiOutlineWhatsApp
                                style={{
                                    width: "32px",
                                    height: "32px",
                                    color: "#FFFF",
                                }}
                            ></AiOutlineWhatsApp>
                        </a>
                    </div>
                    <div className={styles.copyright}>
                        <h1>Copyright @2023 All Rights Reserved Nyahari </h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
