import React from "react";
import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
import styles from "./TasteOfMaharashtra.module.css";
import Backbar from "../components/Backbar";
function TasteOfMaharashtra() {
    return (
        <div className={styles.tasteofmh}>
            <div className={styles.tasteofmhnav}>
                <Backbar></Backbar>
            </div>
            <div className={styles.tasteofmhh1}>
                <h1 className={styles.tasteofmhmainh1}>Chaat</h1>
            </div>

            <div className={styles.tasteofmhcards}>
                <div className={styles.tasteofmh1}>
                    {/* <h1 className={styles.tasteofmh1h1}>Sandwiches</h1> */}
                    <div className={styles.tasteofmh1txt}>
                        <h1>Sprout-up Your Life Chaat</h1>
                        {/* <h2>RS 30</h2> */}
                    </div>
                    <div className={styles.tasteofmh1txt}>
                        <h1>Chana Chatter Chat</h1>
                        {/* <h2>RS 40</h2> */}
                    </div>

                    <div className={styles.tasteofmh1txt}>
                        <h1>Funtastic Paneer Chaat</h1>
                        {/* <h2>RS 40</h2> */}
                    </div>

                    <div className={styles.tasteofmh1txt}>
                        <h1>Mun-chaho Makhana Chaat</h1>
                        {/* <h2>RS 45</h2> */}
                    </div>

                    <div className={styles.tasteofmh1txt}>
                        {/* <h1>Thalipeeth</h1>
            <h2>RS 50</h2> */}
                    </div>

                    <div className={styles.tasteofmh1txt}>
                        {/* <h1>Shevayacha Upma</h1>
            <h2>RS 50</h2> */}
                    </div>
                </div>
            </div>

            <div className={styles.footer}>
                <Footer></Footer>
            </div>
        </div>
    );
}

export default TasteOfMaharashtra;
