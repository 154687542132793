import React from "react";
import Navbar from "../components/Navbar";
import styles from "../css/Home.module.css";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
// import Popup from "./Popup";
// import { useState, useEffect } from "react";
import SocialLinks from "../components/SocialLinks";

function Home() {
    // const [timedPopup, setTimedPopup] = useState(false);

    // const Navigate = useNavigate();
    // const handleClick = () => {
    //     Navigate("/contact");
    // };

    // useEffect(() => {
    //     setTimeout(() => {
    //         setTimedPopup(true);
    //     }, 5000);
    // }, []);
    return (
        <div className={styles.homecontainer}>
            <div>
                <Navbar></Navbar>
            </div>
            <div>
                {/* <Popup trigger={timedPopup} setTrigger={setTimedPopup}>
                    <h1 class="text-5xl mb-10 ">
                        We are <b className="text-[#E02424]">Almost</b> at your
                        place!
                    </h1>
                    <button
                        onClick={handleClick}
                        class=" text-2xl item-center bg-[#818cf8] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Get in touch
                    </button>
                </Popup> */}
            </div>
            <div className={styles.container1}>
                <div className={styles.container1left}>
                    <h1>Order Tasty &</h1>
                    <h2>Fresh Food!</h2>
                    <h3>anytime!</h3>
                    <p>
                        Just confirm your order by clicking on the whatsapp icon
                        below.
                    </p>
                    <Link to="/menu">
                        <button className="w-[30%] h-[60px] text-center">See Menu</button>
                    </Link>
                </div>
                <div className={styles.container1right}>
                    <img
                        src={require("../assets/homeimg.png")}
                        className={styles.container1rightimg}
                        alt="Nyahari"
                    ></img>
                </div>
            </div>

            <div className={styles.container2}>
                <img
                    src={require("../assets/food2.png")}
                    className={styles.container2img1}
                    alt="Nyahari"
                ></img>

                <div className={styles.container2text}>
                    <h1 className="mt-10">Nyahari</h1>
                    <h2 className="text-justify">
                        Having good health is ultimately the result of having a
                        proper diet. To ensure that you are eating the right
                        food at the right time Nyahari provides you a
                        customisable breakfast ordering system. We endow you
                        with the privilege to customize your menu & your time
                        and we will take care of the rest!
                    </h2>
                    <h3 className="text-justify">
                        We ensure nutrition along with the homely love & quality
                        food to make you intake a healthy diet no matter
                        wherever you are.
                    </h3>
                </div>
                <img
                    src={require("../assets/food3.png")}
                    className={styles.container2img2}
                    alt="Nyahari"
                ></img>
            </div>

            <div className={styles.stepscontainer}>
                <h1 className={styles.stepscontainerh1}>
                    Steps to Order Food at Nyahari
                </h1>

                <div className={styles.stepscards}>
                    <a href="https://wa.me/918080275029">
                    <div className={styles.stepscard1}>
                        <img
                            src={require("../assets/13038.jpg")}
                            className={styles.stepscard1img}
                            alt="Nyahari"
                        ></img>
                        <h1>Place your order now </h1>
                    </div>
                    </a>

                    <div className={styles.stepscard4}>
                        <img
                            src={require("../assets/3672341.jpg")}
                            className={styles.stepscard4img}
                            alt="Nyahari"
                        ></img>
                        <h1>Pick Your Order at your Doorstep</h1>
                    </div>
                </div>
            </div>

            <div className={styles.container3}>
                <div className={styles.container3text}>
                    <h1>Hungry?</h1>
                    <h2>Good, We are here to serve you!</h2>
                    <h3>
                        Going out to have breakfast every day eats your most
                        productive time in the morning. Don’t eat your time, eat
                        Nyahari!
                    </h3>
                </div>
                <img
                    src={require("../assets/delivery.png")}
                    className={styles.container3img}
                    alt="Nyahari"
                ></img>
            </div>

            <div className={styles.container3}>
                <img
                    src={require("../assets/watch.png")}
                    className={styles.watchimg}
                    alt="Nyahari"
                ></img>
                <div className={styles.container3text}>
                    <h1>On Time Delivery</h1>
                    <h2>Fast Delivery!</h2>
                    <h3>
                        We deliver your favorite healthy breakfast at your
                        doorstep according to your time schedule.
                    </h3>
                </div>
            </div>

            <div className={styles.container3}>
                <div className={styles.container3text}>
                    <h1>Easy Scheduling</h1>
                    <h2>Schedule your Breakfast weekly!</h2>
                    <h3>Pre-schedule your breakfast easily for a whole week</h3>
                </div>

                <img
                    src={require("../assets/deliveryman2.png")}
                    className={styles.container5img}
                    alt="Nyahari"
                ></img>
            </div>

            <div className={styles.container4}>
                <img
                    src={require("../assets/newsletter1.png")}
                    className={styles.container4img}
                    alt="Nyahari"
                ></img>

                <div className={styles.newsletterrightcontainer}>
                    <h1 className={styles.newsletterh1}>
                        {" "}
                        Subscribe to Newsletter
                    </h1>

                    <div className={styles.container4inputcontainer}>
                        <input placeholder="Enter your email"></input>
                        <button>Subscribe</button>
                    </div>
                </div>
            </div>
            <div>
                <Footer></Footer>
            </div>
            <SocialLinks />
        </div>
    );
}

export default Home;
